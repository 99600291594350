<template>
  <div class="studentCourse">
    <el-card @click="showDialog(c)" shadow="hover" v-for="c in classInfo.list" :key="c.classId">{{c.className}}</el-card>
  </div>
  <el-dialog
        v-model="dialog"
        width="70%"
      >
        <ClassProblemDialog :c="c" />
      </el-dialog>
</template>
<script setup>
import { ref,reactive,onMounted } from "vue"
import {api} from "@/utils/requests"
import ClassProblemDialog from "@/component/ClassProblemDialog.vue"
let dialog=ref(false)
let classInfo=reactive({})
let c=reactive({})
const showDialog=(param)=>{
  Object.assign(c,param)
  dialog.value=true
}

onMounted(()=>{
  api.post("/userClass/listSelf",{page:1,limit:999,sortInfo:{prop:"",order:0},filterInfo:{}}).then((res)=>{
  if(res.data.code===200){
    Object.assign(classInfo,res.data.data)
  }
})
})
</script>

<style scoped>
/* 根据用户获取课程 */
/* 根据用户和课程获取题目 */
.studentCourse {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  margin: 10px 120px;
}
</style>