<template>
  <div class="studentOverview">
    <!-- 上面部分 -->
    <div class="upper-section">
      <el-card shadow="hover">
        <div class="upper-container">
          <!-- 左侧部分（走马灯） -->
          <div class="carousel-section">
            <el-carousel arrow="never">
              <!-- 图片1 -->
              <el-carousel-item v-for="pic in pics" :key="pic">
                <img :src="pic" :alt="pic" />
              </el-carousel-item>
              <!-- 其他图片 -->
            </el-carousel>
          </div>
          <!-- Element Plus 垂直分隔线 -->
          <el-divider direction="vertical"></el-divider>
          <!-- 右侧部分（文本内容） -->
          <div class="content-section">
            <div class="date-section">
              <h2 class="large-date">{{ getDay }}</h2>
              <div class="day-of-week">
                <p>{{ getChineseDayOfWeek }}</p>
              </div>
            </div>
            <div style="font-weight: bold;font-size: 20px;color:gray;">
              <p style="margin:0">{{ getFullDate }}</p>
            </div>
          </div>
        </div>
      </el-card>
    </div>

    <div class="lower-section">
      <div class="left-column">
        <el-card shadow="hover">
          <p>问题跳转</p>
          <el-input v-model="searchId" placeholder="题目ID"></el-input>
          <div style="margin-top: 10px">
            <el-button @click="searchById">跳转</el-button>
            <el-button @click="searchRandom">随机跳转</el-button>
          </div>
        </el-card>
        <NewestDiscussion />
      </div>
      <div class="divider"></div>
      <!-- 用于左右列之间的间隔 -->
      <div class="right-column">
        <NewestProblem />
      </div>
    </div>
  </div>
</template>

<script setup>
import NewestProblem from "@/component/NewestProblem.vue";
import NewestDiscussion from "@/component/NewestDiscussion.vue";
import { ref, computed } from "vue";
import { api } from "@/utils/requests";
import pic1 from "@/assets/1.jpg";
import pic2 from "@/assets/2.jpg";
import pic3 from "@/assets/3.jpg";

let pics = ref([pic1, pic2, pic3]);
//问题跳转
let searchId=ref('');
const searchById=()=>{
  window.open("/game.html?problemId=" + searchId.value, "_blank");
}

const searchRandom=()=>{
  api.get("/problem/findRandom").then((res) => {
    if (res.data.code == 200) {
      window.open("/game.html?problemId=" + res.data.data, "_blank");
    }
  });
}

const getDay = computed(() => {
  const today = new Date();
  return today.getDate();
});

const getChineseDayOfWeek = computed(() => {
  const daysOfWeek = ["日", "一", "二", "三", "四", "五", "六"];
  const today = new Date();
  const dayIndex = today.getDay();
  return "星期"+daysOfWeek[dayIndex];
});

const getFullDate = computed(() => {
  const options = {year: "numeric", month: "long" };
  return new Date().toLocaleDateString("en-US", options);
});
</script>

<style scoped>
.studentOverview {
  display: flex;
  flex-direction: column;
  margin: 10px clamp((100% - 1200px) / 2, 10px, 9999px);
}

.upper-section {
  margin-bottom: 20px;
}

.upper-container {
  display: flex;
}

.carousel-section {
  flex: 5;
}

.carousel-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-section {
  flex: 2;
  padding: 20px;
}

.date-section {
  display: flex;
  align-items: center;
}

.day-of-week {
  margin-left: 10px;
  writing-mode: vertical-lr; /* 设置文字竖排 */
}

.lower-section {
  display: flex;
  flex: 1;
}

.left-column {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  flex: 5;
}

.left-column > * {
  margin-bottom: 10px;
}

.divider {
  width: 20px; /* 左右列之间的间隔宽度 */
}

.right-column {
  align-self: flex-start;
  flex: 2;
}
.large-date {
  font-size: 4em; /* 调整字体大小 */
  margin: 0;
}
</style>

<style>
.studentOverview .upper-container .el-divider--vertical {
  height: auto;
}
</style>