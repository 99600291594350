<template>
  <div class="studentDiscussion">
    <div class="grid">
      <el-card shadow="hover" @click="addPostDialog">
        <template #header>
          <span>发表帖子</span>
        </template>
        <el-icon size="40" color="gray"><DocumentAdd /></el-icon>
      </el-card>

      <el-card
        v-for="post in postData.list"
        :key="post.postId"
        shadow="hover"
        @click="showPostDialog(post)"
      >
        <template #header>
          <span style="margin-right: 5px">{{ post.nick }}</span>
          <el-tag
            type="warning"
            v-if="post.username === getCookieValue('username')"
            style="margin-right: 5px"
            >自己</el-tag
          >
          <span style="float: right">{{ formatTime(post.time) }}</span>
        </template>
        <p>{{ post.content }}</p>
      </el-card>
    </div>

    <!-- Pagination Section -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next, total, sizes"
        :total="postData.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        @prev-click="handlePrev"
        @next-click="handleNext"
      />
    </div>

    <el-dialog v-model="dialog">
      <template #title> 发表新帖子 </template>
      <el-form :model="newPostContent" :rules="rules" ref="newPostForm">
        <el-form-item prop="title">
          <el-input
            v-model="newPostContent.title"
            placeholder="请输入帖子标题"
          />
        </el-form-item>
        <el-form-item prop="content">
          <el-input
            v-model="newPostContent.content"
            type="textarea"
            :rows="4"
            placeholder="请输入帖子内容"
          ></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <el-button @click="dialog = false">取消</el-button>
        <el-button type="primary" @click="createNewPost(newPostForm)"
          >发表</el-button
        >
      </template>
    </el-dialog>

    <el-dialog
      v-model="postDetailsDialog"
      style="min-height: 100%; margin-top: 0; margin-bottom: 0"
    >
      <ReplyDialog :post="post" />
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive } from "vue";
import { api } from "@/utils/requests.js";
import { DocumentAdd } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import ReplyDialog from "@/component/ReplyDialog.vue";
import { getCookieValue } from "@/utils/cookies";

const newPostForm = ref(null);
let post = reactive({});
let dialog = ref(false);
let postDetailsDialog = ref(false);
let postData = reactive({ list: [], total: 0 });
let newPostContent = reactive({
  title: "",
  content: "",
});

const getPostList = (page = 1, limit = 20) => {
  api
    .post("/post/list", {
      limit,
      page,
      sortInfo: { prop: "time", order: -1 },
    })
    .then((res) => {
      postData.list = res.data.data.list;
      postData.total = res.data.data.total;
    });
};

onMounted(() => {
  getPostList();
});

const showPostDialog = (p) => {
  Object.assign(post, p);
  postDetailsDialog.value = true;
};

const addPostDialog = () => {
  newPostContent.title = "";
  newPostContent.content = "";
  dialog.value = true;
};

const createNewPost = (formEl) => {
  formEl.validate((valid) => {
    if (valid) {
      api.post("/post/add", newPostContent).then((res) => {
        if (res.data.code === 200) {
          ElMessage.success("发表成功");
          getPostList();
        }
      });
      dialog.value = false;
    }
  });
};

const formatTime = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const rules = {
  title: [{ required: true, message: "请输入帖子标题", trigger: "blur" }],
  content: [{ required: true, message: "请输入帖子内容", trigger: "blur" }],
};

const handleCurrentChange = (e) => {
  getPostList(e);
};

const handleSizeChange = (e) => {
  getPostList(1, e);
};

const handlePrev = (e) => {
  getPostList(e);
};

const handleNext = (e) => {
  getPostList(e);
};
</script>

<style scoped>
.studentDiscussion {
  margin: 10px 120px;
}
.grid {
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-bottom: 20px;
}

.el-card {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.el-card:hover {
  transform: scale(1.05);
}
</style>
