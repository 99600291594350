<template>
  <!-- 主容器 -->
  <div class="student">
    <el-container style="height: 100vh">
      <!-- 头部 -->
      <el-header class="header">
        <div class="logo">
          <!-- 折叠按钮 -->
          <el-button
            style="
              border: none;
              height: calc(var(--el-header-height) - 2px);
              width: 64px;
            "
            @click="isCollapse = !isCollapse"
          >
            <el-icon v-if="!isCollapse" size="20"><Fold /></el-icon>
            <el-icon v-else size="20"><Expand /></el-icon>
          </el-button>
          <!-- 网站标题 -->
          <h2 style="margin: 0 0 0 10px; cursor: pointer" @click="addTab(0)">
            python兴趣学习平台
          </h2>
        </div>
        <!-- 用户信息下拉菜单 -->
        <div class="content" style="display: flex; align-items: center;gap: 10px">
          <p>{{ username }}</p>

          <el-dropdown trigger="click">
            <el-button circle :icon="MoreFilled"></el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="addTab(4)">个人信息</el-dropdown-item>
                <router-link to="/teacher" style="text-decoration: none">
                  <el-dropdown-item v-if="usertype === '2' || usertype === '0'">
                    切换教师
                  </el-dropdown-item>
                </router-link>
                <router-link to="/admin" style="text-decoration: none">
                  <el-dropdown-item v-if="usertype === '0'">
                    切换管理
                  </el-dropdown-item>
                </router-link>
                <el-dropdown-item divided @click="logout()">
                  退出登录
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      <!-- 侧边栏和主体内容 -->
      <el-container>
        <!-- 侧边栏 -->
        <el-aside width="fit-content">
          <el-menu
            default-active="1"
            :collapse="isCollapse"
            class="el-menu-vertical-demo"
            style="height: 100%"
          >
            <el-menu-item index="1" @click="addTab(0)">
              <el-icon><homeFilled /></el-icon>
              <template #title>首页</template>
            </el-menu-item>
            <!-- 班级管理标签 -->
            <el-menu-item index="2" @click="addTab(1)">
              <el-icon><document /></el-icon>
              <template #title>课程</template>
            </el-menu-item>
            <!-- 课程管理标签 -->
            <el-menu-item index="3" @click="addTab(2)">
              <el-icon><folderOpened /></el-icon>
              <template #title>题库</template>
            </el-menu-item>
            <!-- 题库管理标签 -->
            <el-menu-item index="4" @click="addTab(3)">
              <el-icon><chatSquare /></el-icon>
              <template #title>讨论</template>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <!-- 主体内容区域 -->
        <el-main class="main">
              <component :is="item.content"></component>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script setup>
import { reactive, ref, onMounted } from "vue";
import { api } from "@/utils/requests";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { getCookieValue } from "@/utils/cookies";
import {
  HomeFilled,
  Avatar,
  MoreFilled,
  ChatSquare,
  Fold,
  Expand,
  Document,
  FolderOpened,
  Menu as IconMenu,
} from "@element-plus/icons-vue";
import Overview from "./Overview.vue";
import Info from "../account/Info.vue";
import StudentCourse from "./StudentCourse.vue";
import StudentProblem from "./StudentProblem.vue";
import StudentDiscussion from "./StudentDiscussion.vue";
import { it } from "element-plus/es/locale";

// 变量声明
const isCollapse = ref(true);

const panes = reactive([
  { component: Overview, label: "首页" },
  { component: StudentCourse, label: "课程" },
  { component: StudentProblem, label: "题库" },
  { component: StudentDiscussion, label: "讨论" },
  { component: Info, label: "个人信息" },
]);
let tabIndex = 1;
const editableTabsValue = ref("0");
let item = ref(
  {
    title: "首页",
    name: "0",
    content: Overview,
  }
);
const router = useRouter();
let usertype = ref(getCookieValue("usertype"));
let username = ref(getCookieValue("username"));
console.log(usertype,username)

const addTab = (id) => {
  Object.assign(item.value, {
    title: panes[id].label,
    name: id,
    content: panes[id].component,
  })
}

const logout = () => {
  api.get("/user/logout").then((res) => {
    if (res.data.code == 200) {
      ElMessage.success("退出登陆");
      router.replace({ path: "/login" });
    }
  });
};
onMounted(() => {
  document.title = "python兴趣学习平台";
  let nav = document.querySelector(".el-aside");
  let main = document.querySelector(".main");
  let h = nav.offsetTop;

  window.addEventListener("scroll", function (e) {
    nav.style.height = "100%";
    if (window.pageYOffset > h) {
      nav.style.position = "fixed";
      nav.style.top = "0px";
      main.style.marginLeft = nav.offsetWidth + "px";
    } else {
      nav.style.position = "static";
      main.style.marginLeft = 0;
    }
  });
});
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px 0 0;
  border-bottom: solid 1px rgb(200, 200, 200);
}
.logo {
  display: flex;
  align-items: center;
}
.main {
  padding: 0;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
</style>
<style>
.student .el-tabs__header {
  margin-bottom: 0;
}
.student .el-tabs {
  display: flex;
  flex-direction: column;
}
.student .el-tabs--border-card {
  border: 0;
}
.student .el-tabs__content {
  padding: 0;
}
.student .el-tabs__content,
.el-tabs {
  height: 100%;
}
.student .el-tab-pane {
  height: 100%;
}
.student .el-tabs__nav div:first-child i {
  display: none;
}
</style>
