<template>
  <div class="adminProblem">
    <div>
      <el-form inline>
        <el-form-item>
          <el-input v-model="filterInfo.problemId" placeholder="问题ID" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.title" placeholder="标题" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.description" placeholder="描述" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filterInfo.tags" placeholder="标签" />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="filterInfo.start"
            type="datetime"
            placeholder="from"
          />
          <span style="margin: 0 10px">-</span>
          <el-date-picker
            v-model="filterInfo.end"
            type="datetime"
            placeholder="to"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="filterChange">筛选</el-button>
          <el-button @click="resetFilter">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData.list"
      style="width: 100%"
      @row-click="handleRowClick"
      @sort-change="handleSortChange"
    >
      <el-table-column label="完成情况" width="100px">
        <template #default="scope">
          <el-tag :type="scope.row.completed ? 'success' : 'info'">{{
            scope.row.completed ? scope.row.score : "未完成"
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="problemId"
        sortable="custom"
        label="问题ID"
        width="100px"
      />
      <el-table-column prop="title" sortable="custom" label="标题" />
      <el-table-column prop="description" sortable="custom" label="描述" />
      <el-table-column prop="time" sortable="custom" label="时间">
        <template #default="scope">
          {{ new Date(scope.row.time).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column prop="tags" label="标签">
        <template #default="scope">
          <el-popover
            placement="left"
            trigger="hover"
            v-if="scope.row.tags.split(',').length > 3"
          >
            <div>
              <el-tag
                v-for="(tag, index) in scope.row.tags.split(',')"
                :key="index"
                style="margin: 0 5px 5px 0"
                type="success"
              >
                {{ tag }}
              </el-tag>
            </div>
            <template #reference>
              <div>
                <el-tag
                  v-for="(tag, index) in scope.row.tags.split(',').slice(0, 3)"
                  :key="index"
                  style="margin-right: 5px"
                  type="success"
                >
                  {{ tag }}
                </el-tag>
                <el-tag type="success">...</el-tag>
              </div>
            </template>
          </el-popover>
          <div v-if="scope.row.tags.split(',').length <= 3">
            <div>
              <el-tag
                v-for="(tag, index) in scope.row.tags.split(',')"
                :key="index"
                style="margin-right: 5px"
                type="success"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next, total, sizes"
        :total="tableData.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        @prev-click="handlePrev"
        @next-click="handleNext"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { api } from "@/utils/requests";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import isEqual from "lodash/isEqual";
let router = useRouter();
let page = ref(1);
let limit = ref(10);
let tableData = reactive({
  total: 0,
  list: [],
});
let sortInfo = reactive({
  prop: "",
  order: 0,
});
let filterInfo = reactive({
  problemId: "",
  title: "",
  description: "",
  start: null,
  end: null,
  tags: "",
  path: "", // Add the path field
});

const getProblemList = () => {
  api
    .post("/problem/listSelf", { page, limit, sortInfo, filterInfo })
    .then((res) => {
      if (res.data.code === 200) {
        Object.assign(tableData, res.data.data);
      } else {
        ElMessage.error(res.data.code + ":" + res.data.message);
      }
    });
};

const handleSortChange = (e) => {
  sortInfo.prop = e.prop;
  sortInfo.order =
    e.order === "ascending" ? 1 : e.order === "descending" ? -1 : 0;
  getProblemList();
};

const filterChange = () => {
  getProblemList();
};

const resetFilter = () => {
  filterInfo.problemId = "";
  filterInfo.title = "";
  filterInfo.description = "";
  filterInfo.start = null;
  filterInfo.end = null;
  filterInfo.tags = "";
  filterInfo.path = ""; // Reset the path field
  getProblemList();
};

const handleCurrentChange = (e) => {
  page.value = e;
  getProblemList();
};

const handleSizeChange = (e) => {
  limit.value = e;
  page.value = 1;
  getProblemList();
};

const handlePrev = (e) => {
  page.value = e;
  getProblemList();
};

const handleNext = (e) => {
  page.value = e;
  getProblemList();
};

const handleRowClick = (e) => {
  window.open("/game.html?problemId=" + e.problemId, "_blank");
};

onMounted(() => {
  getProblemList();
});
</script>

<style scoped>
.adminProblem {
  padding: 10px 20px;
}
.pagination {
  padding: 10px 20px;
}
</style>
